import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = ({ siteTitle }) => {
  const [state, setState] = React.useState(false)
  const toggleDrawer = () => {
    setState(!state)
  }
  return (
    <>
      <header>
        <AppBar position="static">
          <Toolbar>
            <div className="md:hidden">
              <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
            </div>
            <Link
              to="/"
              className="inline-block md:ml-4 text-lg md:text-2xl flex-grow text-center md:text-left"
            >
              ふーとのボードゲームログ
            </Link>
            <div className="hidden md:block">
              <ButtonGroup variant="text" color="inherit">
                {/* <Button>このサイトについて</Button> */}
                <Button
                  component="a"
                  href="https://forms.gle/nxUzp161z7SjEPHR9"
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせ
                </Button>
              </ButtonGroup>
            </div>
            <div className="block md:hidden">
              <Drawer open={state} onClose={toggleDrawer}>
                <List>
                  {/* <ListItem button component={Link} to={"#"}>
                    このサイトについて
                  </ListItem> */}
                  <ListItem
                    button
                    component="a"
                    href="https://forms.gle/nxUzp161z7SjEPHR9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText primary="お問い合わせ" />
                  </ListItem>
                </List>
              </Drawer>
            </div>
          </Toolbar>
        </AppBar>
      </header>
      <StaticImage
        loading="eager"
        layout="fullWidth"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/header.png"
        alt="ヘッダー"
      />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import * as React from "react"

const Footer = () => {
  return (
    <footer className="my-4 mr-2 flex justify-end">
      <div>© {new Date().getFullYear()} ふーと</div>
    </footer>
  )
}

export default Footer
